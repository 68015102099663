import { FC } from 'react';
import 'react-multi-carousel/lib/styles.css';

const Intro: FC = () => {
  return (
    <section className="video-container-intro main embed-responsive embed-responsive-16by9">
      <div className="overlay-intro"></div>
      <iframe
        className="iframe-intro"
        src="https://www.youtube.com/embed/CuEAFJmOE8w?playlist=CuEAFJmOE8w&autoplay=1&mute=1&loop=1&controls=0&rel=0&disablekb=1&fs=1"
        title="Remax Sur III"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {/* <!-- Fila 1 (1 caja) --> */}
      <div className="corner-box row-1 box-1"></div>

      {/* <!-- Fila 2 (2 cajas) --> */}
      <div className="corner-box row-2 box-1"></div>
      <div className="corner-box row-2 box-2"></div>

      {/* <!-- Fila 3 (3 cajas) --> */}
      <div className="corner-box row-3 box-1"></div>
      <div className="corner-box row-3 box-2"></div>
      <div className="corner-box row-3 box-3"></div>

      {/* <!-- Fila 4 (4 cajas) --> */}
      <div className="corner-box row-4 box-1"></div>
      <div className="corner-box row-4 box-2"></div>
      <div className="corner-box row-4 box-3"></div>
      <div className="corner-box row-4 box-4"></div>
      {/* <!-- Fila 5 (5 cajas) --> */}
      <div className="corner-box row-5 box-1"></div>
      <div className="corner-box row-5 box-2"></div>
      <div className="corner-box row-5 box-3"></div>
      <div className="corner-box row-5 box-4"></div>
      <div className="corner-box row-5 box-5"></div>
    </section>
  );
};
export default Intro;
