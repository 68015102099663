import { Autocomplete, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Row } from 'reactstrap';

// import { getCitiesSimple, getProperties, getTypes } from '../services/properties.services';
import FormSearchProperty from '../models/FormSearchProperty';
import { CitySimple } from '../models/Location';
import Property from '../models/Property';
import { getCitiesSimple, getProperties, getTypes } from '../services/properties.services';
import { setCities } from '../stores/locationsSlice';
import {
  FiltroSlice,
  resetProperties,
  setFiltro,
  setLoading,
  setPage,
  setProps,
  setTotalItems
} from '../stores/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';
import { setTypes } from '../stores/typesSlice';
import { split } from '../tools/share';
import Paginador from './paginador.component';
import Propiedad from './propiedad.component';

const Propiedades: FC<{ pages: number }> = ({ pages }) => {
  const dispatch = useAppDispatch();
  const filtro = useAppSelector<FiltroSlice>(state => state.properties.filtro);
  const citiesList = useAppSelector<CitySimple[]>(state => state.cities.data);
  const typesList = useAppSelector<string[]>(state => state.types.data);
  const propsList = useAppSelector<Property[]>(state => state.properties.data);

  const page = useAppSelector<number>(state => state.properties.page);
  const itemPerPage = useAppSelector<number>(state => state.properties.itemPerPage);
  const totalItems = useAppSelector<number>(state => state.properties.totalItems);

  const [form, setForm] = useState<FormSearchProperty>({ operation: 1, typesProp: [], cities: [] });

  const [preInit, setPreInit] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [modeGA, setModeGA] = useState<boolean>(false);

  const { descripcion, localidad, operacion } = useParams();

  useEffect(() => {
    const cities = async () => {
      try {
        const citiesList = await getCitiesSimple();
        dispatch(setCities(citiesList));
      } catch (error) {
        console.log(error);
      }
    };
    const types = async () => {
      try {
        const typesList = await getTypes();
        dispatch(setTypes(typesList));
      } catch (error) {
        console.log(error);
      }
    };

    cities();
    types();
  }, []);

  useEffect(() => {
    if (citiesList.length > 0 && typesList.length > 0) {
      loadFilterFromUrl();
      setPreInit(true);
    }
  }, [citiesList, typesList]);

  const loadFilterFromUrl = () => {
    //HACK: Se usa por si viene filtro por url para actualizar el filtro del
    if (descripcion) {
      const descripcionSplit = split(descripcion);
      const descripcionFilterURL = typesList.filter(el =>
        descripcionSplit.some(l => el.toLowerCase().includes(l.toLowerCase()))
      );

      setForm(prevState => ({
        ...prevState,
        typesProp: descripcionFilterURL
      }));
      setModeGA(true);
    }
    switch (operacion?.toLowerCase()) {
      case 'venta':
        setForm(prevState => ({
          ...prevState,
          operation: 2
        }));
        break;
      case 'alquiler':
        setForm(prevState => ({
          ...prevState,
          operation: 3
        }));
        break;
      default:
        setForm(prevState => ({
          ...prevState,
          operation: 1
        }));
        break;
    }

    if (localidad) {
      const localidadesSplit = split(localidad);
      const citiesFilterURL = citiesList.filter(el => localidadesSplit.some(l => el.locality.includes(l)));
      setForm(prevState => ({
        ...prevState,
        cities: citiesFilterURL
      }));
    }
  };

  useEffect(() => {
    if (preInit) {
      dispatch(setFiltro(buildFilter()));
      setInit(true);
    }
  }, [preInit]);

  const propiedades = async () => {
    try {
      // }
      const propiedades = await getProperties({ page, limit: itemPerPage, order: '', sort: '' }, filtro, modeGA);
      dispatch(setProps(propiedades.items));
      dispatch(setTotalItems(propiedades.pagination.total));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (init) {
      if (page === 0) {
        //HACK: En caso que cambie el filtro y siga en la pagina 1, fuerzo pagina 0 para que recargue
        dispatch(setPage(1));
      } else {
        propiedades();
      }
    }
  }, [page, init]);

  const handleSeleccinarLocalidades = (event: React.ChangeEvent<any>, newValue: CitySimple[]) => {
    // HACK: Donde se guardan las ciudades selccionadas
    setForm(prevState => ({
      ...prevState,
      cities: newValue
    }));
  };

  const handleOperation = (event: any) => {
    setForm(prevState => ({
      ...prevState,
      operation: +event.target.value
    }));
  };

  const handleTypes = (event: React.ChangeEvent<any>, newValue: string[]) => {
    setForm(prevState => ({
      ...prevState,
      typesProp: newValue
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // Aquí podrías agregar la lógica para manejar el envío del formulario
    console.log(form);
    dispatch(setFiltro(buildFilter()));
    dispatch(resetProperties());
  };

  const buildFilter = (): FiltroSlice => {
    const filtroSlice: FiltroSlice = {
      operacion: 'TODOS',
      localidad: undefined,
      ciudad: undefined,
      typesProp: undefined
    };
    if (form.cities.length > 0) {
      filtroSlice.localidad = form.cities
        .map(i => {
          return i.locality;
        })
        .join(', ');
    }
    switch (form.operation) {
      case 2:
        filtroSlice.operacion = 'VENTA';
        break;
      case 3:
        filtroSlice.operacion = 'ALQUILER';
        break;
      default:
        filtroSlice.operacion = 'TODOS';
        break;
    }
    if (form.typesProp.length > 0) {
      filtroSlice.typesProp = form.typesProp.map(i => i).join(',');
    }
    return filtroSlice;
  };

  return (
    <section id="propiedades" className="ftco-section bg-light" style={{ padding: '3em 0' }}>
      <Container>
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 heading-section text-center ftco-animate2">
            {/*<span className="subheading">RE/MAX SUR III</span>*/}
            <h2 className="mb-4 font-azul-sec">Nuestras propiedades</h2>
          </div>
        </div>
      </Container>
      <Container maxWidth="sm" style={{ padding: '1em' }}>
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="operation-type-label">Tipo de Operación</InputLabel>
                <Select
                  labelId="operation-type-label"
                  value={form.operation}
                  onChange={handleOperation}
                  label="Tipo de Operación"
                >
                  <MenuItem value="1">TODOS</MenuItem>
                  <MenuItem value="3">ALQUILER</MenuItem>
                  <MenuItem value="2">VENTA</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={typesList}
                value={form.typesProp}
                onChange={handleTypes}
                renderInput={params => <TextField {...params} label="Tipo de propiedad" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={citiesList}
                groupBy={(option: CitySimple) => option.city}
                getOptionLabel={(option: CitySimple) => option.locality}
                value={form.cities}
                onChange={handleSeleccinarLocalidades}
                renderInput={params => <TextField {...params} label="Lugar/Zona" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e: any) => handleSubmit(e)}
                className="background-azul"
                sx={{ mt: 2 }}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Paginador actualPage={page} pages={pages} total={totalItems} />
      <div className="container-fluid">
        <Row>{propsList.length > 0 && propsList.map((p, k) => <Propiedad p={p} key={k} isFeature={false} />)}</Row>
      </div>
      <Paginador actualPage={page} pages={pages} total={totalItems} />
    </section>
  );
};

export default Propiedades;
