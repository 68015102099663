import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useRef, useState } from 'react';
import { Container } from 'reactstrap';

interface FormData {
  nombre: string;
  email: string;
  telefono: string;
  operacion: string;
  tipoDePropiedad: string;
  ubicacion: string;
  habitaciones: string;
  presupuesto: string;
  descripcion: string;
}

const formDataEmpty: FormData = {
  nombre: '',
  email: '',
  telefono: '',
  operacion: '',
  tipoDePropiedad: '',
  ubicacion: '',
  habitaciones: '',
  presupuesto: '',
  descripcion: ''
};

const Contacto: FC = () => {
  const [formData, setFormData] = useState<FormData>(formDataEmpty);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value
    });
  };

  const handleOperation = (event: any) => {
    setFormData(prevState => ({
      ...prevState,
      operacion: event.target.value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form data:', formData);
    // Aquí puedes añadir la lógica para manejar el envío del formulario
  };

  return (
    <section className="ftco-section bg-white" style={{ padding: '3em 0' }}>
      <Container style={{ paddingBottom: '2em' }}>
        <div className="heading-section text-center ftco-animate2">
          <h2 className="mb-4 font-azul-sec">Contanos que buscas y te ayudamos a encontrar tu próxima propiedad</h2>
        </div>
      </Container>
      <Container>
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 1200, margin: '0 auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Nombre"
                variant="outlined"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Teléfono"
                variant="outlined"
                type="tel"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel>Operación</InputLabel>
                <Select name="operation" value={formData.operacion} label="Operación" onChange={handleOperation}>
                  <MenuItem value="Venta">Venta</MenuItem>
                  <MenuItem value="Alquiler">Alquiler</MenuItem>
                  <MenuItem value="Indistinto">Indistinto</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Tipo de propiedad"
                variant="outlined"
                type="text"
                name="tipoDePropiedad"
                fullWidth
                required
                value={formData.tipoDePropiedad}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Ubicación"
                variant="outlined"
                type="text"
                name="ubicacion"
                fullWidth
                required
                value={formData.ubicacion}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Habitaciones"
                variant="outlined"
                type="text"
                name="habitaciones"
                fullWidth
                value={formData.habitaciones}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Presupuesto disponible"
                variant="outlined"
                type="text"
                name="presupuesto"
                fullWidth
                value={formData.presupuesto}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                variant="outlined"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default Contacto;
