import { Outlet, Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

const Layout = () => {
  const [isActive, setIsActive] = useState(false);

  const showMenu = () => {
    console.log(window.innerWidth);
    if (window.innerWidth >= 992) return;
    setIsActive(!isActive);
  };
  const contactoRef = useRef(null);

  // const handleclickRefContactoClick = () => {
  //   contactoRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
        <div className="container">
          <span className="escribinos py-3">Comunicate al WhatsApp +54 9 11 2222-3333 </span>
          <button
            onClick={showMenu}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu"></span> <MenuIcon /> Menu
          </button>
          <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`} id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <button onClick={showMenu} className="nav-link btn-link">
                  Destacadas
                </button>
              </li>
              <li className="nav-item">
                <button onClick={showMenu} className="nav-link btn-link">
                  Propiedades
                </button>
              </li>
              <li className="nav-item">
                <button onClick={showMenu} className="nav-link btn-link">
                  Nuestro Equipo
                </button>
              </li>
              {/* <li className="nav-item">
                <button onClick={clickRefContacto} className="nav-link btn-link">
                  Contacto
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <nav
        className="d-flex justify-content-between align-items-start logo"
        style={{ position: 'absolute', width: '100%', marginTop: '20px', zIndex: '1', padding: '2px' }}
      >
        <img src="/img/remax-logo-blanco.png" className="img-thumbnail logo-remax" alt="logo-remax" />
      </nav>
      <Outlet />
      <footer className="ftco-footer ftco-bg-dark ftco-section" style={{ padding: '3em 0 0 0', height: '100px' }}>
        <Container>
          <div className="row mb-5">
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <img
                  src="/img/remax-logo-blanco.png"
                  className="img-thumbnail"
                  style={{ backgroundColor: 'transparent', border: '0' }}
                  alt="logo-remax"
                />
                <h2 className="ftco-heading-2">Líder mundial en servicios inmobiliarios</h2>
                <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li>
                    <a href="https://googel.com.ar">
                      <span className="icon-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://googel.com.ar">
                      <span className="icon-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="https://googel.com.ar">
                      <span className="icon-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-4 ml-md-5">
                <h2 className="ftco-heading-2">Corredores</h2>
                <ul className="list-unstyled">
                  <li>
                    <a className="py-2 d-block" style={{ color: '#fff' }} href="https://googel.com.ar">
                      Emanuel Dzierza - MAT 12345 BSAS
                    </a>
                  </li>
                  <li>
                    <a className="py-2 d-block" style={{ color: '#fff' }} href="https://googel.com.ar">
                      Martin Veltra - MAT 12345 BSAS
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Donde estamos</h2>
                <iframe
                  title="video"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.422097838937!2d-58.25567932381983!3d-34.71975337291289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32e40a0c20fe1%3A0x9135b1a0f0f21fa5!2sAv.%20Rivadavia%20430%2C%20B1878%20Quilmes%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1724077386885!5m2!1ses!2sar"
                  width="300"
                  height="225"
                  style={{ border: '0' }}
                  allowFullScreen={false}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Conocenos</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li>
                      <span className="icon icon-map-marker"></span>
                      <span className="text" style={{ color: '#fff' }}>
                        Av. Rivadavia 430, B1878 Quilmes, Provincia de Buenos Aires, Argentina
                      </span>
                    </li>
                    <li>
                      <a href="https://googel.com.ar">
                        <span className="icon icon-phone"></span>
                        <span className="text" style={{ color: '#fff' }}>
                          + 54 11 2222 3333
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="https://googel.com.ar">
                        <span className="icon icon-envelope"></span>
                        <span className="text" style={{ color: '#fff' }}>
                          info@yourdomain.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col md={12} className="text-center">
              <p>
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script>
                All rights reserved | This template is made with{' '}
                <i className="icon-heart color-danger" aria-hidden="true"></i> by{' '}
                <a href="https://colorlib.com" target="_blank" rel="noreferrer">
                  Colorlib
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Layout;
