import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// import City from '../models/Location';

interface TypesInfo {
  data: string[];
  loading: boolean;
}

const initialState: TypesInfo = {
  data: [],
  loading: true
};

export const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    setTypes: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

// Exporta las acciones para usarlas en los componentes
export const { setTypes, setLoading } = typesSlice.actions;

// Exporta el reducer para usarlo en el store
export default typesSlice.reducer;
