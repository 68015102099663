import { FilterBackend } from '../dto/filters/Filter';
import { FiltroSlice } from '../stores/propertiesSlice';

export const createFilter = (filters: FilterBackend[]): FiltroSlice => {
  const result: FiltroSlice = {
    operacion: 'TODOS'
  };
  filters.map(el => {
    if (el.field.includes('type')) {
      const operation = el.value.toLowerCase();
      result.operacion = 'TODOS';
      if (operation?.includes('venta')) {
        result.operacion = 'VENTA';
      } else if (operation?.includes('alquiler')) {
        result.operacion = 'ALQUILER';
      }
    }
    if (el.field.includes('locality')) {
      result.localidad = el.value;
    }
  });
  return result;
};

export const capitalizarPrimeraLetra = (palabra: string, separador: string): string => {
  const palabras = palabra.split(separador);
  return palabras
    .map(p =>
      p
        .split(' ')
        .map(sp => sp.charAt(0).toUpperCase() + sp.slice(1).toLowerCase())
        .join(' ')
    )
    .join(',');
};

export const split = (data: string): string[] => {
  if (!data) return [];
  return data
    .split(',')
    .map(word => normalize(word))
    .filter(el => el);
};

const normalize = (word: string): string => {
  return word
    .split(' ')
    .filter(el => el)
    .map(el => firstToUpper(el))
    .join(' ');
};

const firstToUpper = (word: string): string => {
  return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
};
