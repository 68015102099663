import Filter, { FilterBackend } from '../dto/filters/Filter';

export interface Params {
  page: number;
  limit: number;
  order: string;
  sort: string;
  filters?: string;
}

export const addFiltersIfNeeded2 = (filters: FilterBackend[] | undefined, params: Params): Params => {
  if (filters) {
    if (filters.length > 0) params.filters = '&filters=' + encodeURIComponent(JSON.stringify(filters));
  }
  return params;
};

export const addFiltersIfNeeded = (filters: FilterBackend[] | undefined, params: Params): Params => {
  if (filters) {
    const mapped = filters
      .filter(filter => filter.value != null)
      .map(
        filter =>
          ({
            field: filter.field,
            value: Array.isArray(filter.value) ? filter.value.join(',') : filter.value,
            operator: filter.operator,
            type: filter.type
          }) as FilterBackend
      );

    if (mapped.length > 0) params.filters = '&filters=' + encodeURIComponent(JSON.stringify(mapped));
  }
  return params;
};
