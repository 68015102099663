import axios, { AxiosInstance } from 'axios';

import { config } from '../config/config';
// Configuración básica de Axios
const axiosClient: AxiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000 // Tiempo de espera de 10 segundos
});

// Interceptores de solicitud
axiosClient.interceptors.request.use(
  config => {
    // Puedes agregar un token aquí si es necesario
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Interceptores de respuesta
axiosClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Manejo de errores aquí
    if (error.response?.status === 401) {
      console.log('Unauthorized, redirecting to login...');
      // Redirige al usuario a la página de inicio de sesión o maneja la autenticación
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
