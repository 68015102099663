import { FilterBackend } from '../dto/filters/Filter';
import City, { CitySimple } from '../models/Location';
import Property, { PropertyResponse } from '../models/Property';
import { FiltroSlice } from '../stores/propertiesSlice';
import { capitalizarPrimeraLetra } from '../tools/share';
import axiosClient from './axiosClient';
import { addFiltersIfNeeded, Params } from './params';

export const incCompartir = async (publicationId: string) => {
  //http:remaxapi.ddns.net:8080/properties/shared/{publicationId}
  await axiosClient.get(`/properties/shared/${publicationId}`);
};

export const getTypes = async (): Promise<string[]> => {
  try {
    const { data } = await axiosClient.get<string[]>(`/properties/types`);
    return data.sort();
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getProperties = async (
  params: Params,
  filter: FiltroSlice,
  modeGA: boolean
): Promise<PropertyResponse> => {
  try {
    const filters: FilterBackend[] = [];
    if (filter.operacion !== 'TODOS') {
      if (filter.operacion === 'ALQUILER') {
        filters.push({
          field: 'type',
          value: 'Alquiler',
          operator: 'like',
          type: 'string'
        });
      } else {
        filters.push({
          field: 'type',
          value: 'Venta',
          operator: 'like'
          // type: 'string'
        });
      }
    }
    if (filter.localidad) {
      filters.push({
        field: 'locality',
        value: capitalizarPrimeraLetra(filter.localidad, ','),
        operator: 'in'
        // type: 'string'
      });
    } else if (filter.ciudad) {
      filters.push({
        field: 'city',
        value: capitalizarPrimeraLetra(filter.ciudad, ', '),
        operator: 'like'
        // type: 'string'
      });
    }
    if (filter.typesProp) {
      filters.push({
        field: 'propertyType',
        value: filter.typesProp,
        operator: 'in'
        // type: 'string'
      });
    }
    addFiltersIfNeeded(filters, params);
    const { data } = await axiosClient.get(
      `/properties?page=${params.page}&limit=${params.limit}${params.filters ?? ''}&ga=${modeGA ? 1 : 0}`
    );
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getFeatures = async (): Promise<Property[]> => {
  try {
    const { data } = await axiosClient.get(`/properties/featured`);
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getTotales = async (): Promise<{ sales: number; rentals: number; temporary: number }> => {
  try {
    const { data } = await axiosClient.get(`/properties/totals`);
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getCities = async (): Promise<City[]> => {
  try {
    const { data } = await axiosClient.get<City[]>(`/properties/locations`);
    // Ordenar primero por city
    data.sort((a: City, b: City) => a.city.localeCompare(b.city));

    // Ordenar las localities dentro de cada objeto
    data.forEach(item => {
      item.localities.sort((a: string, b: string) => a.localeCompare(b));
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getCitiesSimple = async (): Promise<CitySimple[]> => {
  try {
    const { data } = await axiosClient.get<City[]>(`/properties/locations`);
    const cityLocalities = data.flatMap(item => item.localities.map(locality => ({ city: item.city, locality })));

    return cityLocalities.sort((a: CitySimple, b: CitySimple) => {
      const cityComparison = a.city.localeCompare(b.city);
      if (cityComparison !== 0) {
        return cityComparison;
      }
      return a.locality.localeCompare(b.locality);
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};
