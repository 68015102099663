import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CitySimple } from '../models/Location';

// import City from '../models/Location';

interface LocationsInfo {
  data: CitySimple[];
  loading: boolean;
}

const initialState: LocationsInfo = {
  data: [],
  loading: true
};

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<CitySimple[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

// Exporta las acciones para usarlas en los componentes
export const { setCities, setLoading } = citiesSlice.actions;

// Exporta el reducer para usarlo en el store
export default citiesSlice.reducer;
