import axiosClient from './axiosClient';
import { AgentResponse } from '../models/Agent';

export const getAgents = async (): Promise<AgentResponse> => {
  try {
    const { data } = await axiosClient.get(`/agents`);
    return data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
