import { FC } from 'react';
import { Container } from 'reactstrap';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import { Agent } from '../models/Agent';

const Agentes: FC<{
  agentes: Agent[];
}> = ({ agentes }) => {
  // const fotosRandoms = ['img/s1.jpg','img/s2.jpg','img/s3.jpg','img/s4.jpg','img/s5.jpg','img/s6.jpg','img/s7.jpg']
  const agent = (agent: Agent) => {
    return (
      <div className="staff" key={agent.id}>
        {/* <div className="img" style={{ backgroundImage: `url(${agent.image})` }} /> */}
        <div style={{ textAlign: 'center' }}>
          <img
            src={`${agent.image}`}
            className="img-fluid img-thumbnail img-agent"
            alt={`Remax SUR III ${agent.name}`}
          ></img>
        </div>
        <div className="text pt-4" style={{ margin: '5px' }}>
          <h3>
            <a href="https://google.com.ar">{agent.name}</a>
          </h3>
          <span className="position mb-2">{agent.listing}</span>
          <p>{agent.description}</p>
          <ul className="ftco-social d-flex" style={{ flexDirection: 'row-reverse', fontSize: '25px' }}>
            <li className="fadeInUp">
              <a href="https://wa.link/53zhmx" target="_blank" rel="noreferrer">
                <span className="icon-whatsapp"></span>
              </a>
            </li>
            <li className="fadeInUp">
              <a href="https://google.com.ar">
                <span className="icon-twitter"></span>
              </a>
            </li>
            <li className="fadeInUp">
              <a href="https://google.com.ar">
                <span className="icon-facebook"></span>
              </a>
            </li>
            <li className="fadeInUp">
              <a href="https://google.com.ar">
                <span className="icon-instagram"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <section id="agentes" className="ftco-section bg-light" style={{ padding: '3em 0' }}>
      <Container>
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 heading-section text-center ftco-animate2">
            {/*<span className="subheading">RE/MAX SUR III</span>*/}
            <h2 className="mb-4 font-azul-sec">Nuestro equipo</h2>
          </div>
        </div>
      </Container>
      <Container>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 3,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 2,
              partialVisibilityGutter: 30
            }
          }}
          rewind={true}
          rewindWithAnimation={true}
          // rtl
          shouldResetAutoplay
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {agentes.length > 0 &&
            agentes.map((p, k) =>
              agent({
                ...p,
                listing: '166 publicaciones',
                description: 'Agente inmobiliario'
              })
            )}
        </Carousel>
      </Container>
    </section>
  );
};
export default Agentes;
