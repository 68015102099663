import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Agent } from '../models/Agent';

interface AgentInfo {
  data: Agent[];
  loading: boolean;
}

const initialState: AgentInfo = {
  data: [],
  loading: true
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgents: (state, action: PayloadAction<Agent[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

// Exporta las acciones para usarlas en los componentes
export const { setAgents, setLoading } = agentsSlice.actions;

// Exporta el reducer para usarlo en el store
export default agentsSlice.reducer;
