import Bathtub from '@mui/icons-material/Bathtub';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import NightShelter from '@mui/icons-material/NightShelter';
import ShareIcon from '@mui/icons-material/Share';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import WhatsApp from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { FC, useState } from 'react';
import { Col } from 'reactstrap';

import { config } from '../config/config';
import Property from '../models/Property';
import { incCompartir } from '../services/properties.services';

const publicationUrlWithRedirect = (publicationId: string) => config.API_URL + '/properties/click/' + publicationId;

const Propiedad: FC<{ p: Property; key: number; isFeature: boolean }> = ({ p, key, isFeature }) => {
  const generateIcon = (
    f: string
  ): import('react').ReactElement<any, string | import('react').JSXElementConstructor<any>> | undefined => {
    if (f.includes('ambientes')) {
      return <TagFacesIcon />;
    }
    if (f.includes('baños')) {
      return <Bathtub />;
    }
    if (f.includes('habitacion') || f.includes('dormitorio')) {
      return <NightShelter />;
    }
    return <></>;
  };

  const mainPropery = (p: Property, isFeature: boolean) => {
    return (
      <div className="properties">
        <a
          href={publicationUrlWithRedirect(p.publicationId)}
          target="_blank"
          className="img img-2 prop d-flex justify-content-center align-items-center img-props"
          style={{ backgroundImage: `url(${p.image})` }}
          rel="noreferrer"
        >
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="icon-search2"></span>
          </div>
        </a>
        <div className="text p-3">
          <div className="info">
            <span className="status sale">{p.type?.toUpperCase()}</span>
            <span className="status type">{p.propertyType?.toUpperCase()}</span>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="price">{(p.currency ?? '') + ' ' + p.price}</span>
              <IconButton
                aria-label="compartir"
                onClick={async () => {
                  await incCompartir(p.publicationId);
                  window.open(
                    'https://api.whatsapp.com/send?text=' +
                      encodeURIComponent(
                        'Hola, mira la propiedad que encontre en REM/AX Sur III ' +
                          publicationUrlWithRedirect(p.publicationId)
                      ),
                    '_blank'
                  );
                }}
              >
                <ShareIcon />
              </IconButton>
            </div>
            <p>{p.expenses ?? ''}</p>
          </div>
          <div className="d-flex">
            <div className="one">
              <h3 className="property-description">
                <a href={publicationUrlWithRedirect(p.publicationId)} target="_blank" rel="noreferrer">
                  {p.description}
                </a>
              </h3>
            </div>
          </div>
          <p>{p.address + (p.locality ? ', ' + p.locality : '') + (p.city ? ', ' + p.city : '')}</p>
          {!isFeature && (
            <>
              <p className="agent-info" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <img className="avatar" src={p.contact.image} alt="" />
                <span className="agent-name">{p.contact.name}</span>
              </p>
              <Divider />
              <div className="d-flex align-items-center justify-content-end" style={{ padding: '0.5em 0' }}>
                <IconButton
                  className="background-whastapp"
                  onClick={() => {
                    window.open(
                      'https://wa.me/541167230719?text=Hola, te contacto por la siguiente publicación ' +
                        publicationUrlWithRedirect(p.publicationId),
                      '_blank'
                    );
                  }}
                >
                  <WhatsApp />
                </IconButton>
                <IconButton>
                  <CallIcon />
                </IconButton>
                <IconButton>
                  <MailIcon />
                </IconButton>
              </div>
            </>
          )}
          <Divider />
          <Box sx={{ p: 0 }} style={{ marginTop: '0.5em', padding: '1em 0' }}>
            <Stack direction="row" spacing={{ xs: 1, sm: 2 }} useFlexGap flexWrap="wrap">
              {p.features.length > 0 &&
                p.features.map((f, i) => (
                  <Chip
                    color="primary"
                    className="background-azul"
                    label={f}
                    size="medium"
                    key={i}
                    icon={generateIcon(f)}
                  />
                ))}
            </Stack>
          </Box>
        </div>
      </div>
    );
  };
  return isFeature ? (
    <div key={key} style={{ margin: '0 2em' }}>
      {mainPropery(p, isFeature)}
    </div>
  ) : (
    <Col md={6} lg={4} xl={3} className="fadeInUp ftco-animated" key={key}>
      {mainPropery(p, isFeature)}
    </Col>
  );
};
export default Propiedad;
